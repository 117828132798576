var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('section',{staticClass:"section b_r_l left"},[_c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_c('br'),_c('br'),_c('div',{},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-6-tablet is-3-desktop"},[_vm._m(2),_c('h3',[_vm._v("Prof. Dr. Renate Girmes")]),_c('p',[_vm._v(" Wissenschaftliche Leitung und Geschäftsführung"),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.createMail('girmes@omnimundi.de'))}})])]),_c('div',{staticClass:"column is-6-tablet is-3-desktop"},[_vm._m(3),_c('h3',[_vm._v("Dr. Rainer Totzke")]),_c('p',[_vm._v(" Materialentwicklung und Materialdesign"),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.createMail('totzke@omnimundi.de'))}})])]),_c('div',{staticClass:"column is-6-tablet is-3-desktop"},[_vm._m(4),_c('h3',[_vm._v("Marco Siebert")]),_c('p',[_vm._v(" Webdesigner und Entwickler"),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.createMail('siebert@omnimundi.de'))}})])]),_c('div',{staticClass:"column is-6-tablet is-3-desktop"},[_vm._m(5),_c('h3',[_vm._v("Dr. Henry Sonnet")]),_c('p',[_vm._v("Entwickler"),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.createMail('sonnet@omnimundi.de'))}})])]),_c('div',{staticClass:"column is-6-tablet is-3-desktop"},[_vm._m(6),_c('h3',[_vm._v("Dr. Katharina Ledge")]),_c('p',[_vm._v(" Entwicklerin"),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.createMail('katharinalegde@gmail.com'))}})])]),_c('div',{staticClass:"column is-6-tablet is-3-desktop"},[_vm._m(7),_c('h3',[_vm._v("Susanne Bargel")]),_c('p',[_vm._v(" Materialentwicklerin"),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.createMail('bargel@omnimundi.de'))}})])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns is-mobile left"},[_c('div',{staticClass:"column is-full-mobile"},[_c('h1',[_vm._v("Über uns")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_vm._v(" Da, wo Entfaltung beginnt, eröffnen sich Welten. So steht das Bildungsunternehmen \"Omnimundi\" für die Initiierung und Verstetigung von individuellen Selbst- und gesellschaftlichen Welterkundungsprozessen. "),_c('br'),_c('br'),_vm._v(" Klarheit, Orientierung und nachhaltiges Handeln sind heutzutage wichtiger denn je, weil unsere Gesellschaft immer komplexer wird. Damit die steigende Komplexität nicht zu immer mehr Kompliziertheit und Verwirrung führt, braucht es ganzheitliche Lern- und Bildungsangebote, die uns handlungsfähiger, umsichtiger sowie welt- und selbstbewusster sein lassen. "),_c('br'),_c('br'),_vm._v(" Genau das ist das Ziel unserer Bildungsarbeit und der Motor unseres Denkens und Gestaltens, dem wir mit Sachverstand, Kreativität und Leidenschaft als engagiertes Team nachgehen. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":"/img/team/rgirmes.jpg.webp" + " 300w"}}),_c('img',{staticClass:"masked",attrs:{"src":"'/img/team/rgirmes.jpg","alt":"Prof. Dr. Renate Girmes"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":"/img/team/rainertotzke.jpg.webp" + " 300w"}}),_c('img',{staticClass:"masked",attrs:{"src":"'/img/team/rainertotzke.jpg","alt":"Dr. Rainer Totzke"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":"/img/team/marcosiebert.jpg.webp" + " 300w"}}),_c('img',{staticClass:"masked",attrs:{"src":"'/img/team/marcosiebert.jpg","alt":"Marco Siebert"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":"/img/team/henrysonnet.jpg.webp" + " 300w"}}),_c('img',{staticClass:"masked",attrs:{"src":"'/img/team/henrysonnet.jpg","alt":"Henry Sonnet"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":"/img/team/kathilegde.jpg.webp" + " 300w"}}),_c('img',{staticClass:"masked",attrs:{"src":"'/img/team/kathilegde.jpg","alt":"Dr. Katharina Legde"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":"/img/team/placeholder.webp" + " 300w"}}),_c('img',{staticClass:"masked",attrs:{"src":"'/img/team/placeholder.png","alt":"Susanne Bargel"}})])
}]

export { render, staticRenderFns }