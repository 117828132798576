<template>
	<div class="page">
		<section class="section b_r_l left">

			<div class="container">

				<div class="columns is-mobile left">
					<div class="column is-full-mobile">
						<h1>Über uns</h1>
					</div>
				</div>


				<div class="columns">
					<div class="column">
						Da, wo Entfaltung beginnt, eröffnen sich Welten. So steht das Bildungsunternehmen "Omnimundi" für die Initiierung und Verstetigung von individuellen Selbst- und gesellschaftlichen
						Welterkundungsprozessen.
						<br/><br/>
						Klarheit, Orientierung und nachhaltiges Handeln sind heutzutage wichtiger denn je, weil unsere Gesellschaft immer komplexer wird. Damit die steigende Komplexität nicht zu immer mehr
						Kompliziertheit und Verwirrung führt, braucht es
						ganzheitliche Lern- und Bildungsangebote, die uns handlungsfähiger, umsichtiger sowie welt- und selbstbewusster sein lassen.
						<br/><br/>
						Genau das ist das Ziel unserer Bildungsarbeit und der Motor unseres Denkens und Gestaltens, dem wir mit Sachverstand, Kreativität und Leidenschaft als engagiertes Team nachgehen.

					</div>
				</div>


				<br/><br/>
				<div class="">
					<div class="columns is-multiline">
						<div class="column is-6-tablet is-3-desktop">
							<picture>
								<source type="image/webp"
								        srcset="/img/team/rgirmes.jpg.webp 300w"
								/>
								<img src="'/img/team/rgirmes.jpg" class="masked" alt="Prof. Dr. Renate Girmes"/>
							</picture>

							<h3>Prof. Dr. Renate Girmes</h3>
							<p>
								Wissenschaftliche Leitung und Geschäftsführung<br/>
								<span v-html="createMail('girmes@omnimundi.de')"></span>
							</p>
						</div>
						<div class="column is-6-tablet is-3-desktop">
							<picture>
								<source type="image/webp"
								        srcset="/img/team/rainertotzke.jpg.webp 300w"
								/>
								<img src="'/img/team/rainertotzke.jpg" class="masked" alt="Dr. Rainer Totzke"/>
							</picture>
							<h3>Dr. Rainer Totzke</h3>
							<p>
								Materialentwicklung und Materialdesign<br/>
								<span v-html="createMail('totzke@omnimundi.de')"></span>
							</p>
						</div>

						<!-- <div class="column is-6-tablet is-3-desktop">
						  <picture>
							<source type="image/webp"
									srcset="/img/team/arnestuckmann.jpg.webp 300w"
							/>
							<img src="'/img/team/arnestuckmann.jpg" class="masked" alt="Arne Stuckmann"/>
						  </picture>
						  <h3>Arne Stuckmann</h3>
						  <p>
							Graphische Gestaltung und Materialentwicklung<br/>
							<span v-html="createMail('stuckmann@omnimundi.de')"></span>
						  </p>
						</div> -->

						<!--            <div class="column is-6-tablet is-3-desktop">-->
						<!--              <picture>-->
						<!--                <source type="image/webp"-->
						<!--                        srcset="/img/team/sandrageschke.jpg.webp 300w"-->
						<!--                />-->
						<!--                <img src="'/img/team/sandrageschke.jpg" class="masked" alt="Dr. Sandra Geschke"/>-->
						<!--              </picture>-->
						<!--              <h3>Dr. Sandra Maria Geschke</h3>-->
						<!--              <p>-->
						<!--                Curriculare Vorbereitung, Materialentwicklung und Materialdesign<br/>-->
						<!--                <span v-html="createMail('geschke@omnimundi.de')"></span>-->
						<!--              </p>-->
						<!--            </div>-->

						<div class="column is-6-tablet is-3-desktop">
							<picture>
								<source type="image/webp"
								        srcset="/img/team/marcosiebert.jpg.webp 300w"
								/>
								<img src="'/img/team/marcosiebert.jpg" class="masked" alt="Marco Siebert"/>
							</picture>
							<h3>Marco Siebert</h3>
							<p>
								Webdesigner und Entwickler<br/>
								<span v-html="createMail('siebert@omnimundi.de')"></span>
							</p>
						</div>

						<div class="column is-6-tablet is-3-desktop">
							<picture>
								<source type="image/webp"
								        srcset="/img/team/henrysonnet.jpg.webp 300w"
								/>
								<img src="'/img/team/henrysonnet.jpg" class="masked" alt="Henry Sonnet"/>
							</picture>
							<h3>Dr. Henry Sonnet</h3>
							<p>Entwickler<br/>
								<span v-html="createMail('sonnet@omnimundi.de')"></span>
							</p>
						</div>

						<div class="column is-6-tablet is-3-desktop">
							<picture>
								<source type="image/webp" srcset="/img/team/kathilegde.jpg.webp 300w"/>
								<img src="'/img/team/kathilegde.jpg" class="masked" alt="Dr. Katharina Legde"/>
							</picture>
							<h3>Dr. Katharina Ledge</h3>
							<p>
								Entwicklerin<br/>
								<span v-html="createMail('katharinalegde@gmail.com')"></span>
							</p>
						</div>

						<div class="column is-6-tablet is-3-desktop">
							<picture>
								<source type="image/webp" srcset="/img/team/placeholder.webp 300w"/>
								<img src="'/img/team/placeholder.png" class="masked" alt="Susanne Bargel"/>
							</picture>
							<h3>Susanne Bargel</h3>
							<p>
								Materialentwicklerin<br/>
								<span v-html="createMail('bargel@omnimundi.de')"></span>
							</p>
						</div>

					</div>
				</div>

			</div>

		</section>

		<!-- <svg class="tri__down" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 102" preserveAspectRatio="none">
		  <path d="M0 0 L50 100 L100 0 Z" fill="#FFFFFF"></path>
		</svg> -->

	</div>

</template>

<script>

// import {TimelineMax} from 'gsap'
// import { swiper, swiperSlide } from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css'

export default {
	name: 'about',

	components: {
		// swiper, swiperSlide
	},

	data() {
		return {
			swOpts: {
				autoplay: {
					delay: 2000
				},
				effect:   'fade'
			},
			// Audio
			sound: null,
		}
	},

	mounted() {

	},

	computed: {
		// swiper() {
		//   return this.$refs.mySwiper.$swiper
		// }
	},

	methods: {

		createMail(mail) {
			return `<a href="mailto:${ mail }">Email</a>`
		}


	},
	beforeDestroy() {
		// this.$refs.mySwiper.destroy()    // AutoDestroy is defaulted to True
	}

}
</script>
<style lang="scss">


.columns.gap .column {
	padding : 2rem;
	}

.columns.gap-h .column {
	padding-left  : 2rem;
	padding-right : 2rem;
	}

.b_r_l {
	background : white;

	h1 {
		margin : 0;
		}
	}
</style>
